import React from "react";
import { graphql } from "gatsby";
import PageNotFound from "../components/404/404";
import AboutBlog from "../components/HomePage/AboutBlog/AboutBlog";
import Blog from "../components/HomePage/Blog/Blog";
import Layout from "../components/layouts/layout";
const NotFoundPage = ({ data }) => {
  const posts = data?.allGhostPost?.edges;
  const indexBlock = data?.allGhostPage.edges[0]
  return (<Layout>
    <PageNotFound />
    <AboutBlog indexBlock={indexBlock} />
    <div id="all_blogs">
      <Blog posts={posts} />
    </div>
  </Layout>)

}

export default NotFoundPage

export const pageQuery = graphql`
  query GhostPostQuery404 {
    allGhostPost(
      sort: {fields: published_at, order: DESC}

      filter: {tags: {elemMatch: {name: {eq: "MY_BRAZIL"}}}}
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allGhostPage(filter: {slug: {eq: "my_brazil_home-page"}}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
  }
`;
