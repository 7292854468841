import React from "react";
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Button } from 'react-bootstrap'
// import img from ""

const PageNotFound = () => {
  return (
    <div className='page-not-found' >
      <div className='img-side' >
        <StaticImage src="../../../static/images/404-red.svg" />
      </div>
      <div className='text-side' >
        <h1>Мы не смогли найти страницу <br /> по вашей ссылке</h1>
        <p>
          Не расстраивайтесь!
        </p>
        <p>
          Возможно что-то из статей ниже <br /> вам понравится?
        </p>
        <Button className='SignUp_modal_btn' onClick={() => navigate("/")} >
          на главную
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound